import throttle from "./helper/throttle";

class Header {
  constructor() {
    this.header = document.getElementById("header");

    window.addEventListener(
      "scroll",
      throttle(this.checkScrollPosition.bind(this), 50)
    );

    this.scrollPosition = window.scrollY;
    this.direction = "down";

    this.checkScrollPosition();
  }

  checkScrollPosition() {
    const height = window.innerHeight * 0.5;

    this.direction = window.scrollY > this.scrollPosition ? "down" : "up";
    this.scrollPosition = window.scrollY;

    if (this.direction === "down" && this.scrollPosition > height) {
      this.header.classList.add("is-scrolled");
    } else {
      this.header.classList.remove("is-scrolled");
    }

    if (this.scrollPosition > height) {
      this.header.classList.add("is-shrinked");
    } else {
      this.header.classList.remove("is-shrinked");
    }
  }
}

export default Header;
