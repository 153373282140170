import "lazysizes";

import HeroHeadline from "./blocks/heroHeadline";
import DarkModeToggle from "./darkMode";
import Navigation from "./navigation";
import Header from "./header";

(() => {
  new DarkModeToggle();
  new Navigation();
  new Header();

  document.querySelectorAll(".hero-headline").forEach((item) => {
    new HeroHeadline(item);
  });
})();
