import Cookies from "js-cookie";

class DarkModeToggle {
  constructor() {
    this.darkModeToggle = document.getElementById("darkmode-toggle");
    this.lightModeToggle = document.getElementById("lightmode-toggle");

    this.darkModeToggle.addEventListener("click", () =>
      this.switchTheme("dark")
    );
    this.lightModeToggle.addEventListener("click", () =>
      this.switchTheme("light")
    );

    window.addEventListener("keydown", (e) => {
      if (e.code === "KeyB") return this.switchTheme("dark");
      if (e.code === "KeyW") return this.switchTheme("light");
    });

    this.getDarkMode();
  }

  switchTheme(newTheme) {
    const currentTheme = document.body.getAttribute("data-theme");
    if (currentTheme === newTheme) return;

    document.body.setAttribute("data-theme", newTheme);
    Cookies.set("theme", newTheme);

    if (newTheme === "dark") {
      this.darkModeToggle.classList.add("is-active");
      this.lightModeToggle.classList.remove("is-active");
    }

    if (newTheme === "light") {
      this.darkModeToggle.classList.remove("is-active");
      this.lightModeToggle.classList.add("is-active");
    }
  }

  getDarkMode() {
    const cookieTheme = Cookies.get("theme");

    if (cookieTheme === "dark" || cookieTheme === "light") {
      this.switchTheme(cookieTheme);
    } else if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      this.switchTheme("dark");
    }
  }
}

export default DarkModeToggle;
