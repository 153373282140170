import throttle from "../helper/throttle";

export default class HeroHeadline {
  constructor(element) {
    this.words = element.dataset.words.split(", ");
    this.currentX = 0;
    this.currentWord = element.dataset.currentWord.trim();
    this.element = element;
    this.hovered = false;

    this.initEventListener();
  }

  getRandomWord() {
    const newWord = this.words[Math.floor(Math.random() * this.words.length)];

    if (newWord === this.currentWord) {
      return this.getRandomWord();
    }

    return newWord;
  }

  updateCurrentWord() {
    const newWord = this.getRandomWord();

    this.element.dataset.currentWord = newWord;
    this.currentWord = newWord;

    const newSpan = document.createElement("span");
    const newContent = document.createTextNode(newWord);
    newSpan.appendChild(newContent);

    this.element.innerHTML = "";
    this.element.appendChild(newSpan);
  }

  initEventListener() {
    this.element.addEventListener("mouseenter", () => {
      this.hovered = true;
    });

    this.element.addEventListener("mouseleave", () => {
      this.hovered = false;
    });

    this.element.addEventListener("click", this.updateCurrentWord.bind(this));

    window.addEventListener(
      "mousemove",
      throttle(({ screenX }) => {
        const triggerWidth = Math.max(window.innerWidth / 40, 40);

        const lastX = this.currentX;
        this.currentX = screenX;

        if (
          (screenX > lastX + triggerWidth || screenX < lastX - triggerWidth) &&
          this.hovered
        ) {
          this.updateCurrentWord();
        }
      }, 200)
    );

    window.addEventListener("shake", this.updateCurrentWord.bind(this));
  }
}
