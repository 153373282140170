class Navigation {
  constructor() {
    this.menuToggle = document.getElementById("menu-toggle");
    this.headerNavigation = document.getElementById("header-navigation");

    this.menuToggle.addEventListener("click", this.toggleNavigation.bind(this));
  }

  toggleNavigation() {
    if (this.headerNavigation.classList.contains("is-open")) {
      this.headerNavigation.classList.remove("is-open");
      this.menuToggle.classList.remove("is-open");
      this.menuToggle.setAttribute("aria-label", "Open menu");
    } else {
      this.headerNavigation.classList.add("is-open");
      this.menuToggle.classList.add("is-open");
      this.menuToggle.setAttribute("aria-label", "Close menu");
    }
  }
}

export default Navigation;
